import {combineReducers} from 'redux'
import wizardReducer from './wizard/wizardReducer'
import firestoreReducer from './firestore/firestoreReducer'
import userReducer from './user/userReducer'

const rootReducer = combineReducers({
    wizard: wizardReducer,
    firestore: firestoreReducer,
    user: userReducer
})

export default rootReducer