import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import NavBar from '../NavBar';

import { connect } from 'react-redux';
import { removeOption, addOption, setDecisionType } from '../../redux'

import MyButton from '../utilities/MyButton';
import Container from '@material-ui/core/Container';
import ItemList from './ItemList';
import { getColors } from '../utilities/Colors';
import OptionText from './OptionsText';
import OptionsInput from './OptionsInput';


const useStyles = makeStyles({
    root: {
        '& > *': {
            margin: 1,
            flexGrow: 1
        },
        title: {
            flexGrow: 1,
        },
    },
    button: {
        backgroundColor: colors => colors.primaryColor,
        color: colors => colors.primaryTextColor,
        '&:hover': {
          backgroundColor: colors => colors.secondaryColor,
          color: colors => colors.primaryTextColor
        }
    },
});

function getOptions(options) {
    var newArr = options.map(function (row) {
        return row.choice;
    });
    return newArr;
};

function Options(props) {

    const { match, setDecisionType } = props
    const type = match.params.type;
    const colors = getColors(type);
    const classes = useStyles(colors);

    let itemList; 

    React.useEffect(() => {
        setDecisionType(type)
    }, [setDecisionType, type])

    if (getOptions(props.options).length > 0){
        itemList = 
        <div>
            <br/>
            <ItemList items={getOptions(props.options)} onDelete={props.removeOption}/>
        </div>
    }

    return (
        <div>
            <NavBar type={type} colors={colors}/>
            <div className={classes.root}>
                    <div align="center">
                        <Container maxWidth="sm">
                            <OptionText type={type}/>
                            {itemList}
                            <br/>
                            <OptionsInput type={type} addOption={props.addOption}/>
                        </Container>
                        <div className={classes.root}>
                            <MyButton 
                                variant="contained"
                                name="Back"
                                to="/"
                                >
                                Back
                            </MyButton>
                            <MyButton 
                                variant="contained"
                                colors={colors}
                                name="Continue"
                                to={`/${type}/factors`}
                            >
                            </MyButton>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = state => {
    return {
        options: state.wizard.options,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDecisionType: (type) => dispatch(setDecisionType(type)),
        removeOption: index => dispatch(removeOption(index)),
        addOption: option => dispatch(addOption(option))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Options)