import React from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Accordion from '../utilities/Accordion';
import { connect } from 'react-redux';
import { addGrade } from '../../redux';
import NavBar from '../NavBar';
import { getColors } from '../utilities/Colors';

import MyButton from '../utilities/MyButton';
import MySlider from '../utilities/MySlider';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            {children}
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
        'text-overflow': 'ellipsis',
        'width':'100px',
    };
}

const useStyles = makeStyles({
    root: {
      '& > *': {
          margin: 1,
          flexGrow: 1
      },
      title: {
          flexGrow: 1,
      },
      table: {
        minWidth: 100,
      },
      button: {
        margin: 15
      },
    },
    table: {
        fontSize: 24,
    },
    tab: {
        minWidth: 150, // a number of your choice
        width: 150,
    },
    appBar: {
        backgroundColor: colors => colors.primaryColor,
    },
    indicator: {
        backgroundColor: colors => colors.primaryTextColor
    },
    textField: {
        "& label.Mui-focused": {
            color: colors => colors.primaryColor,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors => colors.primaryColor,
        }
    },
    slider: {
        color: colors => colors.primaryColor,
    }
  });

function Scoring(props){

    const { match } = props
    const type = match.params.type;
    const colors = getColors(type);
    const classes = useStyles(colors);
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    function getTable(i) {
        let table = []; 
        for (let j = 0; j < props.factors.length; j++){
            
            let suggested;

            if (props.factors[j].suggestedGrades[i] === 0){
                suggested = ''
            }else{
                suggested = 
                <div>
                    <i>Suggested: </i>{props.factors[j].suggestedGrades[i]}
                </div>
            }
            if (props.factors[j].selected){
                table.push(
                    <TableRow key={j}>
                        <TableCell component="th" scope="row" padding="none">
                            <div style={{ width: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <Box 
                                    pl={3}                                 
                                    textOverflow="ellipsis"
                                    component="div"
                                    overflow="hidden"
                                    >

                                    {props.factors[j].factor}
                                    <br/>
                                    <div style={{
                                        color: "grey", 
                                        fontSize: '13px'
                                        }}
                                    >{suggested}</div>
                                </Box>
                            </div>
                        </TableCell>
                        <TableCell padding="none">    
                            <Box 
                                pt={6}
                                pr={4}
                            >
                                <MySlider
                                    colors={colors}
                                    defaultValue={props.factors[j].grades[i]}
                                    index={i}
                                    index2={j}
                                    addGrade={props.addGrade}
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                )
            }
        }
        return table;
    }
    

    return (
       <div>
        <NavBar type={type} colors={colors} />
            <div className={classes.root}>
                    <div align="center">
                        <Container maxWidth="sm">
                            <Typography variant="h4">
                                Scoring
                            </Typography>
                            <br/>
                            <Box textAlign="left" varient="body1" display='block'>
                                <Typography component="div">
                                    Score each factor below by giving it a number 1-10. 
                                    1 would be a low score and 10 would be high score.
                                </Typography>
                            </Box>
                            <br/>
                            <Accordion summary="More details" details={<>The scores will be multipled by the importance value to get a weighted score. The Decision Matrix will calculate a weighted score for each option to determine which option is the best. A factor with a score of 1 would mean that the given option did not score well in that factor and the option's weighted score would decrease. A factor with a score of 10 would be mean that the given option scored very well in that factor and the option's weighted score would increase.</>}/>
                            <br/>
                            <div className={classes.appBar}>
                                <AppBar className={classes.appBar} position="static">
                                    <Tabs 
                                        className={classes.appBar} 
                                        value={value} 
                                        onChange={handleChange} 
                                        classes={{
                                            indicator: classes.indicator
                                        }}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs">
                                    {props.options.map((row, index) => (
                                        <Tab classes={{ root: classes.tab}} key={index} label={row.choice} {...a11yProps(index)} />
                                    ))} 
                                    </Tabs>
                                </AppBar>
                                {props.options.map((row, index) => (
                                    <TabPanel key={index} value={value} index={index}>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left" padding="none">
                                                            <Box p={3}>
                                                                <b>Factor</b>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="left" padding="none">
                                                            <Box pb={3} pr={12} pt={3}>
                                                                <b>Score</b>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {getTable(index)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TabPanel>
                                ))}
                            </div> 
                        </Container>
                        <br/>
                        <div className={classes.root}>
                            <MyButton 
                                variant="contained"
                                name="Back"
                                to={`/${type}/importance`}
                                >
                                Back
                            </MyButton>
                            <MyButton 
                                variant="contained"
                                colors={colors}
                                name="Continue"
                                to={`/${type}/results`}
                            >
                            </MyButton>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const mapStateToProps = state => {
    return {
        factors: state.wizard.factors,
        options: state.wizard.options,
        colors: state.wizard.colors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addGrade: (index, index2, grade)  => dispatch(addGrade(index, index2, grade))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scoring)
