import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles({
    root: {
      '& > *': {
          margin: 1,
          flexGrow: 1
      },
      title: {
          flexGrow: 1,
      },
    },
  });

function Accordian(props) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{props.summary}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box textAlign="left" varient="body1">
                        {props.details}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default Accordian