import ReactGA from 'react-ga';

const trackingId = 'UA-169393308-1'

function init() {
    ReactGA.initialize(trackingId);    
}

function sendEvent(payload) {
    ReactGA.event(payload)
}
   
function sendPageview(path) {
    ReactGA.set({ page: path })
    ReactGA.pageview(path)
}
   
export default {
    init,
    sendEvent,
    sendPageview,
}

