import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '../utilities/Accordion';
import NavBar from '../NavBar';
import MyButton from '../utilities/MyButton';
import { getColors } from '../utilities/Colors';
import Slider from '@material-ui/core/Slider';

import { connect } from 'react-redux';
import { addImportance } from '../../redux'
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
      '& > *': {
          margin: 1,
          flexGrow: 1
      },
      title: {
          flexGrow: 1,
      },
      table: {
            fontSize: 24,
      },
      overrides: {
        // Style sheet name ⚛️
        MuiTableHead: {
          // Name of the rule
          font: {
            // Some CSS
            size: '24px',
          },
        },
      },
    },
    textField: {
        "& label.Mui-focused": {
            color: colors => colors.primaryColor,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors => colors.primaryColor,
        }
    },
    slider: {
        color: colors => colors.primaryColor,
    },
    MuiTableCell: {
        root: {  
            padding: '1px 1px',
            backgroundColor: "#eaeaea",
        },
    },
  });

function Importance(props){

    const { match } = props
    const type = match.params.type;
    const colors = getColors(type);
    const classes = useStyles(colors);

    let table = []; 

    for (let j = 0; j < props.factors.length; j++){
        if (props.factors[j].selected){
            table.push(
                <TableRow key={j}>
                    <TableCell component="th" scope="row" padding="none">        
                        <Box pl={3} style={{width: 120, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {props.factors[j].factor}
                        </Box>
                    </TableCell>
                    <TableCell align="center" scope="row" padding="none">
                        <Box pt={6} pr={4}>
                            <Slider
                                className={classes.slider}
                                valueLabelDisplay="on"
                                defaultValue={props.factors[j].importance}
                                aria-labelledby="discrete-slider"
                                step={1}
                                marks
                                min={0}
                                max={5}
                                onChange={(e, v) => props.addImportance(props.factors[j].factor, v)}
                            />
                        </Box>
                    </TableCell>    
                </TableRow>
            )
        }
    }

    return (
        <div>
        <NavBar type={type} colors={colors}/>
        <div className={classes.root}>
            <div align="center">
                <Container maxWidth="sm">
                    <Typography variant="h4">
                        Importance
                    </Typography>
                    <br/>
                    <Typography align="left" varient="body1" display='block'>
                        Add a number 1-5 for each factor, based on how 
                        important that factor is to you. 1 would be low importance and 5 would 
                        be high importance.
                    </Typography>
                    <br/> 
                    <Accordion summary="More details" details={<>1 - Low importance <br/> 2 - Low-medium importance <br/> 3 - Medium importance <br/> 4 - Medium-high Importance <br/> 5 - High importance <br/></>}/>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" padding="none">
                                        <Box p={3}>
                                            <b>Factor</b>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="left" padding="none">
                                        <Box pb={3} pr={12} pt={3}>
                                            <b>Importance</b>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {table}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
                <br/>
                <div className={classes.root}>
                    <MyButton 
                        variant="contained"
                        name="Back"
                        to={`/${type}/factors`}
                        >
                        Back
                    </MyButton>
                    <MyButton 
                        variant="contained"
                        colors={colors}
                        name="Continue"
                        to={`/${type}/scoring`}
                    >
                    </MyButton>
                </div>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        factors: state.wizard.factors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addImportance: (factor, importance)  => dispatch(addImportance(factor, importance))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Importance)