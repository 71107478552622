import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Welcome from './Welcome';
import Results from './wizard/Results';
import Results2 from './wizard/Results2';
import Question from './wizard/Question';
import Join from './Join';
import Profile from './Profile';
import SignIn from './SignIn';
import SignedOut from './SignedOut'
import Decisions from './Decisions';
import Options from './wizard/Options';
import Factors from './wizard/Factors';
import Importance from './wizard/Importance';
import Scoring from './wizard/Scoring';
import LandingPage3 from './LandingPage3';
import useGoogleAnalytics from '../useGoogleAnalytics';

function Routes() {
    useGoogleAnalytics()
   
    return (
        <Switch>
            <Route path="/" exact component={LandingPage3} />
            <Route path="/:type/welcome" exact component={Welcome} />
            <Route path="/:type/question" component={Question} />
            <Route path="/:type/options" component={Options} />
            <Route path="/:type/factors" component={Factors} />
            <Route path="/:type/importance" component={Importance} />
            <Route path="/:type/scoring" component={Scoring} />
            <Route path="/:type/results" exact component={Results} />
            <Route path="/:type/results/:id" component={Results2} />
            <Route path="/join" component={Join} />
            <Route path="/sign_in" component={SignIn} />
            <Route path="/signed_out" component={SignedOut} />
            <Route path="/profile" component={Profile} />
            <Route path="/decisions" component={Decisions} />
        </Switch>
    )
  }

function Matrix() {
    return (
        <Router>
            <div>
                <Routes />
            </div>
        </Router>
    );
}

export default Matrix