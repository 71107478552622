import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import MyCard from '../components/utilities/MyCard';
import { connect } from 'react-redux';
import { resetWizard, resetFirestore } from '../redux';
import { Box } from '@material-ui/core';
import NavBar from './NavBar';
import { getColors } from './utilities/Colors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: 150
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
}));


function LandingPage2(props) {

  const classes = useStyles();
  const colors = getColors(null);

  const { resetFirestore, resetWizard } = props;



  React.useEffect(() => {
    resetWizard();
    resetFirestore();
  }, [resetWizard, resetFirestore])

  return (
    <div>
      <NavBar type={"decision matrix"} colors={colors}/>
    <div>
        <br/>
        <Typography variant="h4" color="textPrimary">
          Need help making a decision?
        </Typography>
        <br/>
        <Box pr={1} pl={1}>
          <Typography variant="subtitle1" color="textSecondary">
            Our AI powered algorithms help people make their most important life decisions
          </Typography>
        </Box>
        <br/>
    </div>
    <div className={classes.root}>
        <br/>
        <Grid container spacing={2} style={{padding: 20}}>
            <MyCard
                title="Career"
                type="career"
                subtitle="Take your opportunities and weight them against your passions"
            >
            </MyCard>
            <MyCard
                title="Education"
                type="education"
                subtitle="Find the school that will set you up for future success"
            >
            </MyCard>
            <MyCard
                title="Relocation"
                type="relocation"
                subtitle="Arrive in a new city that you are excited to live in"
            >
            </MyCard>
            <MyCard
                title="Home"
                type="home"
                subtitle="Home is always where the heart is"
            >
            </MyCard>
            <MyCard
                title="Relationship"
                type="relationship"
                subtitle="Grow the relationship that makes you happy and levels you up"
            >
            </MyCard>
            <MyCard
                title="Custom"
                type="custom"
                subtitle="Blaze your own trail by building a custom decision"
            >
            </MyCard>
        </Grid>
    </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
      resetWizard: () => dispatch(resetWizard()),
      resetFirestore: () => dispatch(resetFirestore())
  }
}

export default connect(null, mapDispatchToProps)(LandingPage2)