import React from 'react'
import AddChoice from './AddChoice';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import NavBar from '../NavBar';
import { Box } from '@material-ui/core';
import MyButton from '../utilities/MyButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { getColors } from '../utilities/Colors';

import { connect } from 'react-redux';
import { removeFactor, addFactor, selectFactor, getSuggestedFactors, setDecisionType } from '../../redux'


const useStyles = makeStyles({
    root: {
      '& > *': {
          margin: 1,
          flexGrow: 1
      },
      title: {
          flexGrow: 1,
      },
    },
  });

function Factors(props){
        
    const classes = useStyles();
    const { match, getSuggestedFactors, setDecisionType } = props
    const type = match.params.type;
    const colors = getColors(type);

    React.useEffect(() => {
        setDecisionType(type)
        if (props.factors.length === 0){
            getSuggestedFactors(type)
        }
    }, [props.factors, type, getSuggestedFactors, setDecisionType])

    return (
        <div>
        <NavBar type={type} colors={colors}/>
            <div className={classes.root}>
                <div align="center">
                    <Container maxWidth="sm">
                        <Typography variant="h4">
                            Factors
                        </Typography>
                        <br/>
                        <Typography align="left" varient="body1" display='block'>
                            Add the factors that matter most to you when 
                            weighing your options.
                        </Typography>
                        <br/>
                        <Box display="flex" flexWrap="wrap">
                        {props.factors.map((row, index) => (
                            <Box p={1}>
                                <ToggleButton
                                    value={row.factor}
                                    selected={row.selected}
                                    onChange={() => {
                                        props.selectFactor(row.factor)
                                    }}
                                >
                                    {row.factor}
                                </ToggleButton>
                            </Box>
                        ))}
                        </Box>
                        <Box mt={2} mb={2}>
                                <AddChoice 
                                addItem={props.addFactor} 
                                buttonText="Enter a new factor"
                                colors={colors}
                                />
                        </Box>
                    </Container>
                    <div className={classes.root}>
                        <MyButton 
                            variant="contained"
                            name="Back"
                            to={`/${type}/options`}
                            >
                            Back
                        </MyButton>
                        <MyButton 
                            variant="contained"
                            colors={colors}
                            name="Continue"
                            to={`/${type}/importance`}
                        >
                        </MyButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        factors: state.wizard.factors,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDecisionType: (type) => dispatch(setDecisionType(type)),
        getSuggestedFactors: user => dispatch(getSuggestedFactors(user)),
        removeFactor: index => dispatch(removeFactor(index)),
        addFactor: factor => dispatch(addFactor(factor)),
        selectFactor: factor => dispatch(selectFactor(factor))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Factors)
