import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Accordion from '../utilities/Accordion';
import NavBar from '../NavBar';
import MyButton from '../utilities/MyButton';

import TextField from '@material-ui/core/TextField';

import { connect } from 'react-redux'
import { addQuestion } from '../../redux'

import '../../styles/wizard.css'


const useStyles = makeStyles({
  root: {
    '& > *': {
        margin: 1,
        flexGrow: 1
    },
    menuButton: {
        marginRight: 2,
    },
  },
  textField: {
    "& label.Mui-focused": {
        color: props => props.primaryColor,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: props => props.primaryColor,
    }
},
});

function Question(props) {

    const classes = useStyles(props.colors);

    return (
    <div>
        <NavBar />
        <div className={classes.root}>
            <div align="center">
                <Container maxWidth="sm">
                    <Typography className={classes.typography} variant="h4">
                        What decision are you making?
                    </Typography>
                    <br/>
                    <Typography className={classes.typography} align="left" varient="subtitle1" display='block'>
                        Please write down the question you are trying to answer. 
                    </Typography>
                    <br/>
                        <Accordion summary="Some examples" details={<><li>Which job opportunity should I take?</li>
                                    <li>Which college should I attend?</li>
                                    <li>Which boy or girl should I date?</li>
                                    <li>Which city should I move to?</li>
                                    <li>Which recipe should I cook for my dinner guests?</li></>}
                        />
                        <br/>
                        <div>
                            <TextField
                                className={classes.textField}
                                label="Enter your question"
                                fullWidth
                                margin="normal"
                                onChange={e => props.addQuestion(e.target.value)}
                                defaultValue={props.question}
                            />
                        </div>
                </Container>
                <br/>
                <div className={classes.root}>
                    <MyButton 
                        variant="contained"
                        primaryColor={'#e0dfe0'}
                        primaryTextColor={'black'}
                        secondaryColor={'#d5d5d5'}
                        secondaryTextColor={'white'}
                        name="Back"
                        to="/"
                        >
                    </MyButton>
                    <MyButton 
                        variant="contained"
                        primaryColor={props.colors.primaryColor}
                        primaryTextColor={props.colors.primaryTextColor}
                        secondaryColor={props.colors.secondaryColor}
                        secondaryTextColor={props.colors.secondaryTextColor}
                        name="Continue"
                        to="/options"
                    >
                    </MyButton>
                </div>
            </div>
        </div>
    </div>
    );
}

const mapStateToProps = state => {
    return {
        question: state.wizard.question,
        colors: state.wizard.colors
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addQuestion: question => dispatch(addQuestion(question))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Question)