export const ADD_DECISION_REQUEST = 'ADD_DECISION_REQUEST'
export const ADD_DECISION_SUCCESS = 'ADD_DECISION_SUCCESS'
export const ADD_DECISION_FAILURE = 'ADD_DECISION_FAILURE'
export const ADD_FIRESTORE_ID_SUCCESS = 'ADD_FIRESTORE_ID_SUCCESS'
export const ADD_FIRESTORE_ID_REQUEST = 'ADD_FIRESTORE_ID_REQUEST'
export const UPDATE_DECISION_REQUEST = 'UPDATE_DECISION_REQUEST'
export const UPDATE_DECISION_SUCCESS = 'UPDATE_DECISION_SUCCESS'
export const UPDATE_DECISION_FAILURE = 'UPDATE_DECISION_FAILURE'
export const GET_DECISIONS_REQUEST = 'GET_DECISIONS_REQUEST'
export const GET_DECISIONS_SUCCESS = 'GET_DECISIONS_SUCCESS'
export const GET_DECISIONS_FAILURE = 'GET_DECISIONS_FAILURE'
export const DELETE_DECISION_REQUEST = 'DELETE_DECISION_REQUEST'
export const DELETE_DECISION_SUCCESS = 'DELETE_DECISION_SUCCESS'
export const DELETE_DECISION_FAILURE = 'DELETE_DECISION_FAILURE'
export const SET_FIRESTORE = 'SET_FIRESTORE'
export const RESET_FIRESTORE = 'RESET_FIRESTORE'
export const GET_DECISION_SUCCESS = 'GET_DECISION_SUCCESS'
export const GET_DECISION_FAILURE = 'GET_DECISION_FAILURE'