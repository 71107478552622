import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCrf62me5xrpHnW9tN4SKN8_7TRgJLFAAo",
    authDomain: "decision-matrix-1ef12.firebaseapp.com",
    databaseURL: "https://decision-matrix-1ef12.firebaseio.com",
    projectId: "decision-matrix-1ef12",
    storageBucket: "decision-matrix-1ef12.appspot.com",
    messagingSenderId: "371367235772",
    appId: "1:371367235772:web:8f43e43d1c8c69f19880b9",
    measurementId: "G-VZNXC559TL"
  };
  // Initialize Firebase
  firebase.initializeApp(config);

  export default firebase;