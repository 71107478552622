import React, { useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AddChoice from './AddChoice';
import { getColors } from '../utilities/Colors';
import cities from '../../data/cities.json'
import colleges from '../../data/colleges.json'
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
    root: {
        '& > *': {
            margin: 1,
            flexGrow: 1
        },
        title: {
            flexGrow: 1,
        },
    },
    button: {
        backgroundColor: colors => colors.primaryColor,
        color: colors => colors.primaryTextColor,
        '&:hover': {
          backgroundColor: colors => colors.secondaryColor,
          color: colors => colors.primaryTextColor
        }
    },
    textField: {
        "& label.Mui-focused": {
            color: colors => colors.primaryColor,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors => colors.primaryColor,
        }
    },
});

function OptionsInput(props) {

    const [ term, setTerm ] = useState(null); 

    const colors = getColors(props.type);
    const classes = useStyles(colors);

    function handleChange(event, value){
        if (value){
            setTerm(value.NAME + ", " + value.STNAME)
        }
    }

    function handleChange2(event, value){
        if (value){
            setTerm(value.Name)
        }
    }

    let input; 

    function getOptions(data, type) {
        const options = data.map((option) => {
            let firstLetter;
            if (type === "relocation"){
                firstLetter = option.NAME[0].toUpperCase();
            } else if (type === "education"){
                firstLetter = option.Name[0].toUpperCase();
            }
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
            };
        });
        return options;
    }

    if (props.type === "relocation") {
        const options = getOptions(cities, props.type);
        input = 
            <Box p={1}>
                <Autocomplete
                    id={term}
                    options={options.sort((a, b) => -b.NAME.localeCompare(a.NAME))}
                    getOptionLabel={(option) => option.NAME + ", " + option.STNAME}
                    style={{ width: 250 }}
                    renderInput={(params) => <TextField className={classes.textField} {...params} label="Add a city" variant="standard" />}
                    onChange={(event, value) => handleChange(event, value)}
                />
                <Box p={2}>
                <Button className={classes.button}
                    variant="contained"
                    onClick={() => {
                        if (term) {
                            props.addOption(term);
                            setTerm('')
                        }
                    }}
                >
                    Add
                </Button>
                </Box>
            </Box>;
    } else if (props.type === "education") {
        const options = getOptions(colleges, props.type);
        input = 
            <Box p={1}>
                <Autocomplete
                    id="grouped-demo"
                    options={options.sort((a, b) => -b.Name.localeCompare(a.Name))}
                    getOptionLabel={(option) => option.Name}
                    style={{ width: 250 }}
                    renderInput={(params) => <TextField className={classes.textField} {...params} label="Add a school" variant="standard" />}
                    onChange={(event, value) => handleChange2(event, value)}
                />
                <Box p={2}>
                <Button className={classes.button}
                    variant="contained"
                    onClick={() => {
                        if (term) {
                            props.addOption(term);
                            setTerm('')
                        }
                    }}
                >
                    Add
                </Button>
                </Box>
            </Box>;
            
    } else if (props.type === "career") {
        input = 
        <div>
        <AddChoice 
            addItem={props.addOption} 
            buttonText="Add a job"
            colors={colors}
        />
        <br/>
        </div>;
    } else if (props.type === "home") {
        input = 
        <div>
        <AddChoice 
            addItem={props.addOption} 
            buttonText="Add a home"
            colors={colors}
        />
        <br/>
        </div>;
    } else if (props.type === "relationship") {
        input = 
        <div>
        <AddChoice 
            addItem={props.addOption} 
            buttonText="Add a person"
            colors={colors}
        />
        <br/>
        </div>;
    } else if (props.type === "custom") {
        input = 
        <div>
        <AddChoice 
            addItem={props.addOption} 
            buttonText="Add an option"
            colors={colors}
        />
        <br/>
        </div>;
    } 

    return (
        <div>
            {input}
        </div>
    )
}

export default OptionsInput