import React     from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getColors } from './utilities/Colors';
import MyButton from './utilities/MyButton';

import NavBar from './NavBar';


const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
        margin: theme.spacing(1),
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
    },
  },
}));

const SignedOut = (props) => {

    const classes = useStyles();
    const colors = getColors(null);

    return (
        <div>
        <NavBar type={"decision matrix"} colors={colors} />
        <div className={classes.root}>
        <div align="center">
            <Container maxWidth="sm">
                    <Typography variant="h4">
                        Thank you
                    </Typography>
                    <br/>
                    <Typography variant="body1">
                        You are now signed out. Please come back for your next decision!
                    </Typography>
                    <div className={classes.root}>
                        <br/>
                        <MyButton 
                            variant="contained"
                            name="Sign in again"
                            colors={colors}
                            to="/sign_in"
                        >
                            Sign in again
                        </MyButton>
                        <MyButton 
                            variant="contained"
                            name="Welcome page"
                            to="/"
                            >
                            Welcome page
                        </MyButton>
                    </div>
                </Container>
            </div>
        </div>
        </div>
    );
};

export default SignedOut;
