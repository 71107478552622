import React, { useState } from "react";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { userJoin } from '../redux';

const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
          margin: theme.spacing(1),
          flexGrow: 1
      },
      title: {
          flexGrow: 1,
      },
    },
    button: {
        backgroundColor: colors => colors.primaryColor,
        color: colors => colors.primaryTextColor,
        '&:hover': {
          backgroundColor: colors => colors.secondaryColor,
          color: colors => colors.primaryTextColor
        }
    },
    textField: {
        "& label.Mui-focused": {
            color: colors => colors.primaryColor,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors => colors.primaryColor,
        }
    },
  }));

function JoinForm(props) {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");

    const classes = useStyles(props.colors);
    
    const handleForm = e => {
        e.preventDefault();
        props.userJoin(name, email, password)
    }

    return (
        <div>
            <Typography variant="h4">
                Join  
            </Typography>
            <div className={classes.root}>
                <TextField className={classes.textField} fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                    name="name"
                    type="name"
                    placeholder="name"
                />
                <br/>
                <TextField className={classes.textField} fullWidth
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    name="email"
                    type="email"
                    placeholder="email"
                />
                <br/>
                <TextField className={classes.textField} fullWidth
                    onChange={e => setPassword(e.target.value)}
                    name="password"
                    value={password}
                    type="password"
                    placeholder="password"
                />
                <br/>
                <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={(e) => handleForm(e)}
                >
                Join
                </Button>
            </div>
            <span>{props.error}</span>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        error: state.user.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        userJoin: (name, email, password) => dispatch(userJoin(name, email, password))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinForm)