import React from "react";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import LoadingSpinner from './utilities/LoadingSpinner';
import JoinForm from './JoinForm';
import { connect } from 'react-redux';
import Joined from "./Joined";
import NavBar from './NavBar';
import { getColors } from './utilities/Colors';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
        margin: theme.spacing(1),
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
    },
  },
}));

function Join(props) {

  const colors = getColors(null);
  const classes = useStyles();

  if (!props.loading && !props.loaded) {
    return (
      <div>
        <NavBar type={"decision matrix"} colors={colors}/>
      <div className={classes.root}>
        <div align="center">
        <Container maxWidth="xs">
            <JoinForm colors={colors}/>
          </Container>
        </div>
      </div>
      </div>
    );
  } else if (props.loading && !props.loaded) {
    return (
      <div>
        <NavBar type={"decision matrix"} colors={colors}/>
      <div className={classes.root}>
        <div align="center">
        <Container maxWidth="xs">
            <LoadingSpinner />
          </Container>
        </div>
      </div>
      </div>
    );
  } else {
    return (
      <div>
        <NavBar type={"decision matrix"} colors={colors}/>
      <div className={classes.root}>
        <div align="center">
        <Container maxWidth="xs">
            <Joined colors={colors}/>
          </Container>
        </div>
      </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
      loading: state.user.loading,
      loaded: state.user.loaded
  }
}

export default connect(mapStateToProps, null)(Join)
