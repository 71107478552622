import { 
    ADD_DECISION_REQUEST,
    ADD_DECISION_SUCCESS,
    ADD_DECISION_FAILURE,
    ADD_FIRESTORE_ID_SUCCESS,
    ADD_FIRESTORE_ID_REQUEST,
    UPDATE_DECISION_REQUEST,
    UPDATE_DECISION_SUCCESS,
    UPDATE_DECISION_FAILURE,
    GET_DECISIONS_REQUEST,
    GET_DECISIONS_SUCCESS,
    GET_DECISIONS_FAILURE,
    DELETE_DECISION_REQUEST,
    DELETE_DECISION_SUCCESS,
    DELETE_DECISION_FAILURE,
    SET_FIRESTORE,
    RESET_FIRESTORE,
    GET_DECISION_SUCCESS,
    GET_DECISION_FAILURE
} from './firestoreTypes'

const initialState = {
    decisions: [],
    decision: {},
    suggestedFactors: [],
    reload: false,
    firestoreId: '',
    error: ''
}

const firestoreReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_DECISION_REQUEST:
            return {
                ...state
            }
        case ADD_DECISION_SUCCESS:
            return {
                ...state,
                firestoreId: action.payload,
            }
        case ADD_DECISION_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case ADD_FIRESTORE_ID_SUCCESS:
            return {
                ...state,
                reload: true
            }
        case ADD_FIRESTORE_ID_REQUEST:
            return {
                ...state
            }
        case UPDATE_DECISION_REQUEST:
            return {
                ...state
            }
        case UPDATE_DECISION_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_DECISION_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case GET_DECISIONS_REQUEST:
            return {
                ...state
            }
        case GET_DECISIONS_SUCCESS:
            return {
                ...state,
                decisions: action.payload,
                reload: false
            }
        case GET_DECISIONS_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case DELETE_DECISION_REQUEST:
            return {
                ...state
            }
        case DELETE_DECISION_SUCCESS:
            return {
                ...state,
                reload: true
            }
        case DELETE_DECISION_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case SET_FIRESTORE:
            return {
                ...state,
                decisions: [],
                reload: false,
                firestoreId: action.payload.firestoreId,
                error: ''
            }
        case RESET_FIRESTORE: 
            return {
                ...state,
                decisions: [],
                reload: false,
                firestoreId: '',
                error: ''
            }
        case GET_DECISION_SUCCESS:
            return {
                ...state,
                decision: action.payload
            }
        case GET_DECISION_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default: {
            return state
        }
    }
}

export default firestoreReducer