import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import mandy from '../images/mandy.jpg';
import steve from '../images/steve.jpg';
import michael from '../images/michael.jpg';
import olympia from '../images/olympia.jpg';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { Link } from 'react-router-dom';
import Review from './utilities/Reviews';
import { connect } from 'react-redux';
import NavBar from './NavBar';

import MyButton from './utilities/MyButton';


const useStyles = makeStyles({
  root: {
    '& > *': {
        margin: 1,
        flexGrow: 1
    },
    menuButton: {
        marginRight: 2,
    },
  },
  button: {
    color: props => props.primaryColor,
    '&:hover': {
      color: props => props.secondaryColor
    }
  },
});

function Welcome(props) {
    
    const classes = useStyles(props.colors);

    let buttons;

    if (props.user.name !== ""){
    buttons = <div className={classes.root}>
                <MyButton 
                    variant="contained"
                    primaryColor={props.colors.primaryColor}
                    primaryTextColor={props.colors.primaryTextColor}
                    secondaryColor={props.colors.secondaryColor}
                    secondaryTextColor={props.colors.secondaryTextColor}
                    name="Get Started!"
                    to="/question"
                >
                </MyButton>
            </div>;
    }else{
    buttons = <div className={classes.root}>
                <MyButton 
                    variant="contained"
                    primaryColor={props.colors.primaryColor}
                    primaryTextColor={props.colors.primaryTextColor}
                    secondaryColor={props.colors.secondaryColor}
                    secondaryTextColor={props.colors.secondaryTextColor}
                    name="Get Started!"
                    to="/question"
                >
                </MyButton>
                <Button className={classes.button}
                    color="primary"
                    component={Link}
                    to="/join"
                >
                    Join
                </Button>
            </div>;
    }

    return (
        <div>
        <NavBar />
        <div className={classes.root}>
            <div align="center">
                <Container maxWidth="sm">
                    <Typography variant="h3">
                        Welcome!
                    </Typography>
                    <br/>
                    <Typography align="left" varient="body1" display='block'>
                        We can help you make an important decision in a data driven way. Below are the steps we'll walk you through to help you make a calculated decision. 
                    </Typography>
                        <Box className={classes.root} align="left" component="ul" varient="body1" display='block'>
                            <Typography>
                            1. You add the options you're trying to decide between.<br/>
                            2. You add the factors that matter most to you.<br/>
                            3. You add an importance ranking for each factor.<br/>
                            4. You score each option you're deciding between. <br/>
                            5. Click calculate and you get your answer.<br/>
                            </Typography>
                        </Box>
                    {buttons}
                </Container>
            </div>
            <Grid container spacing={2} style={{padding: 20}}>
                <Review image={mandy} name="Brianna Williams" review="I used the Matrix to decide which wedding venue to go with! There were so many attributes for each option and the Matrix simplified our decision making process."/>
                <Review image={steve} name="Steve Miller" review="I couldn't decide which college I should attend, they all seemed like great options! After using the Matrix it was obvious. 2 years later I couldn't be happier with my decision!"/>
                <Review image={michael} name="Michael Yang" review="I like going to gym every day. There are 10 gyms in my neighborhood. I used the Matrix and boom! I haven't been happier."/>
                <Review image={olympia} name="Anusha Singh" review="Making a dinner for many guests can be hard. I quickly used the Matrix and the decision was made for me. Thank you!"/>
            </Grid>
        </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        colors: state.wizard.colors
    }
  }

  export default connect(mapStateToProps, null)(Welcome)