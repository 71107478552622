import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import education from "../../icons/graduation-hat.svg"; 
import relocation from "../../icons/placeholder.svg"; 
import career from "../../icons/presentation.svg"; 
import relationship from "../../icons/love-letter_1.svg"; 
import custom from "../../icons/magnifying-glass.svg";
import home from "../../icons/home.svg";

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      minHeight: 150
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: 450
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
      height: 151
    },
  }));
  

function MyCard(props) {

    const classes = useStyles();

    let image;

    if (props.type === "relocation"){
        image = relocation;
    } else if (props.type === "education"){
        image = education;
    } else if (props.type === "career"){
        image = career;
    } else if (props.type === "relationship"){
        image = relationship;
    } else if (props.type === "custom"){
        image = custom;
    } else if (props.type === "home"){
        image = home;
    }

    return (
        <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Link to={`/${props.type}/options`} style={{ textDecoration: 'none' }}>
                <Card className={classes.root}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography component="h5" variant="h5">
                                {props.title}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {props.subtitle}
                            </Typography>
                        </CardContent>
                    </div>
                    <CardMedia
                        className={classes.cover}
                        component="img"
                        src={image}
                        title={props.title}
                    />
                </Card>
            </Link>
        </Grid>
    )
}

export default MyCard