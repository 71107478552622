import { 
    ADD_DECISION_REQUEST,
    ADD_DECISION_SUCCESS,
    ADD_DECISION_FAILURE,
    ADD_FIRESTORE_ID_SUCCESS,
    ADD_FIRESTORE_ID_REQUEST,
    UPDATE_DECISION_REQUEST,
    UPDATE_DECISION_SUCCESS,
    UPDATE_DECISION_FAILURE,
    GET_DECISIONS_REQUEST,
    GET_DECISIONS_SUCCESS,
    GET_DECISIONS_FAILURE,
    DELETE_DECISION_REQUEST,
    DELETE_DECISION_SUCCESS,
    DELETE_DECISION_FAILURE,
    SET_FIRESTORE,
    RESET_FIRESTORE,
    GET_DECISION_SUCCESS,
    GET_DECISION_FAILURE
} from './firestoreTypes'

import { firestore } from 'firebase';

export const addDecisionRequest = () => {
    return {
        type: ADD_DECISION_REQUEST
    }
}

export const addDecisionSuccess = firestoreId => {
    return {
        type: ADD_DECISION_SUCCESS,
        payload: firestoreId
    }
}

export const addDecisionFailure = error => {
    return {
        type: ADD_DECISION_FAILURE,
        payload: error
    }
}

export const addFirestoreIdSuccess = firestoreId => {
    return {
        type: ADD_FIRESTORE_ID_SUCCESS,
        payload: firestoreId
    }
}

export const addFirestoreIdRequest = () => {
    return {
        type: ADD_FIRESTORE_ID_REQUEST
    }
}

export const updateDecisionRequest = () => {
    return {
        type: UPDATE_DECISION_REQUEST
    }
}

export const updateDecisionSuccess = firestoreId => {
    return {
        type: UPDATE_DECISION_SUCCESS,
        payload: firestoreId
    }
}

export const updateDecisionFailure = error => {
    return {
        type: UPDATE_DECISION_FAILURE,
        payload: error
    }
}

export const getDecisionsRequest = user => {
    return {
        type: GET_DECISIONS_REQUEST,
        payload: user
    }
}

export const getDecisionsSuccess = decisions => {
    return {
        type: GET_DECISIONS_SUCCESS,
        payload: decisions
    }
}

export const getDecisionsFailure = error => {
    return {
        type: GET_DECISIONS_FAILURE,
        payload: error
    }
}

export const deleteDecisionRequest = () => {
    return {
        type: DELETE_DECISION_REQUEST
    }
}

export const deleteDecisionSuccess = deletedDecision => {
    return {
        type: DELETE_DECISION_SUCCESS,
        payload: deletedDecision
    }
}

export const deleteDecisionFailure = error => {
    return {
        type: DELETE_DECISION_FAILURE,
        payload: error
    }
}

export const setFirestore = firestoreState => {
    return {
        type: SET_FIRESTORE,
        payload: firestoreState
    }
}

export const resetFirestore = () => {
    return {
        type: RESET_FIRESTORE
    }
}

export const getDecisionSuccess = (wizard) => {
    return {
        type: GET_DECISION_SUCCESS,
        payload: wizard
    }
}

export const getDecisionFailure = error => {
    return {
        type: GET_DECISION_FAILURE,
        payload: error
    }
}

export const deleteDecision = (decision) => {
    return (dispatch) => {
        dispatch(deleteDecisionRequest)
        console.log(decision.firestore.firestoreId)
        firestore().collection("matrix").doc(decision.firestore.firestoreId).delete()
        .catch((error) => {
            dispatch(deleteDecisionFailure)
        });
        dispatch(deleteDecisionSuccess(decision.firestore.firestoreId));
    }
}

export const getDecisions = (user) => {
    return (dispatch) => {
        dispatch(getDecisionsRequest);
        firestore().collection("matrix").get()
        .then((res) => {
            var results = [];
            console.log(res)
            res.docs.forEach(doc => {
                if (user !== '' && user.uid !== '' && doc.data().user !== null && doc.data().user.user !== '' && doc.data().user.user.uid === user.uid) {
                    return results.push({...doc.data()});
                }
            });
            dispatch(getDecisionsSuccess(results))
        })
        .catch((error) => {
            dispatch(getDecisionsFailure(error))
        });
    }
}


export const getDecision = (id) => {
    return (dispatch) => {
        firestore().collection("matrix").doc(id).get()
        .then((res) => {
            console.log(res.data())
            dispatch(getDecisionSuccess(res.data().wizard))
        })
        .catch((error) => {
            dispatch(getDecisionFailure(error))
        });
    }
}

export const saveDecision = (decision) => {
    return (dispatch) => {
        dispatch(addDecisionRequest)
        firestore().collection("matrix").add(decision)
        .then(docRef => {
            console.log(docRef.id)
            dispatch(addDecisionSuccess(docRef.id))
            dispatch(addFirestoreIdRequest(docRef.id))
            firestore().collection("matrix").doc(docRef.id).set({...decision, firestore: {firestoreId: "" + docRef.id}})
            .then(() => {
                console.log('updated')
                dispatch(addFirestoreIdSuccess(docRef.id))
            })
            .catch(error => {
                console.log(error)
            })
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(addDecisionFailure(errorMsg))
        })
    }
}

export const updateDecision = (firestoreId, decision) => {
    return (dispatch) => {
        dispatch(updateDecisionRequest)
        firestore().collection("matrix").doc(firestoreId).set({...decision})
        .then(() => {
            dispatch(updateDecisionSuccess(firestoreId))
        })
        .catch((error) => {
            console.log(error)
            dispatch(updateDecisionFailure(error))
        });
    }
}