import {
    ADD_QUESTION,
    ADD_OPTION,
    REMOVE_OPTION,
    ADD_FACTOR,
    SELECT_FACTOR,
    REMOVE_FACTOR,
    ADD_IMPORTANCE,
    ADD_GRADE,
    SET_WIZARD,
    RESET_WIZARD,
    ADD_COLORS,
    GET_S_FACTORS_SUCCESS,
    SET_DECISION_TYPE
} from "./wizardTypes"

import { firestore } from 'firebase';

export const addQuestion = (question) => {
    return {
        type: ADD_QUESTION,
        payload: question
    }
}

export const addOption = (option) => {
    return {
        type: ADD_OPTION,
        payload: option
    }
}

export const removeOption = (index) => {
    return {
        type: REMOVE_OPTION,
        payload: index
    }
}

export const addFactor = (factor) => {
    return {
        type: ADD_FACTOR,
        payload: factor
    }
}

export const selectFactor = (factor) => {
    return {
        type: SELECT_FACTOR,
        payload: factor
    }
}

export const removeFactor = (index) => {
    return {
        type: REMOVE_FACTOR,
        payload: index
    }
}

export const addImportance = (factor, importance) => {
    return {
        type: ADD_IMPORTANCE,
        payload: {
            factor: factor,
            importance: importance
        }
    }
}

export const addGrade = (i, j, grade) => {
    return {
        type: ADD_GRADE,
        payload: {
            optionIndex: i,
            factorIndex: j,
            grade: grade
        }
    }
}

export const setWizard = (wizard) => {
    return {
        type: SET_WIZARD,
        payload: wizard
    }
}

export const resetWizard = () => {
    return {
        type: RESET_WIZARD
    }
}

export const addColors = (colors) => {
    return {
        type: ADD_COLORS,
        payload: colors
    }
}

export const setDecisionType = (type) => {
    return {
        type: SET_DECISION_TYPE,
        payload: type
    }
}

export const getSFactorsSuccess = (suggestedFactors) => {
    return {
        type: GET_S_FACTORS_SUCCESS,
        payload: suggestedFactors
    }
}


export const getSuggestedFactors = (type) => {
    return (dispatch) => {
        firestore().collection("suggestedFactors").doc(type).get()
        .then((res) => {
            console.log(res.data().values)
            dispatch(getSFactorsSuccess(res.data().values))
        })
        .catch((error) => {
        })
    }
}