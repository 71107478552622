import React from "react";
import Typography from '@material-ui/core/Typography';

function LoadingSpinner() {
    return (
        <div>
            <Typography variant="h4">
                Loading!
            </Typography>
        </div>
    )
}

export default LoadingSpinner