import React, { useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    root: {
      '& > *': {
          margin: 1,
          flexGrow: 1
      },
    },
    button: {
        backgroundColor: colors => colors.primaryColor,
        color: colors => colors.primaryTextColor,
        '&:hover': {
          backgroundColor: colors => colors.secondaryColor,
          color: colors => colors.primaryTextColor
        }
    },
    textField: {
        "& label.Mui-focused": {
            color: colors => colors.primaryColor,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors => colors.primaryColor,
        }
    },
  });

function AddChoice(props){

    const [ term, setTerm ] = useState(''); 

    const { colors } = props;

    const classes = useStyles(colors);

    function handleClick(){
        if (term){
            props.addItem(term)
            setTerm('')
        }
    }

    return (
        <div className={classes.root}>
            <TextField className={classes.textField}
                label={props.buttonText}
                value={term}
                onChange={e => setTerm(e.target.value)}
            />
            <Button className={classes.button}
                variant="contained"
                onClick={() => handleClick()}
            >
                Add
            </Button>
    </div>
    )
}


export default AddChoice
