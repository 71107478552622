import React from "react";
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { Link } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: colors => colors.primaryColor,
    color: colors => colors.primaryTextColor,
    '&:hover': {
      backgroundColor: colors => colors.secondaryColor,
      color: colors => colors.primaryTextColor
    }
  },
}));

function SignedIn(props) {

  const classes = useStyles(props.colors);

  return (
    <div>
        <Typography variant="h4">
              Welcome back {props.user.name}!
        </Typography>
        <br/>
        <Button className={classes.button}
            color="primary"
            variant="contained"
            component={Link}
            to="/"
        >
          Start a new decision
        </Button>
    </div>
  );
};

const mapStateToProps = state => {
  return {
      user: state.user.user
  }
}

export default connect(mapStateToProps, null)(SignedIn)
