import { 
    ADD_QUESTION,
    ADD_OPTION,
    REMOVE_OPTION,
    ADD_FACTOR,
    SELECT_FACTOR,
    REMOVE_FACTOR,
    ADD_IMPORTANCE,
    ADD_GRADE,
    SET_WIZARD,
    RESET_WIZARD,
    ADD_COLORS,
    GET_S_FACTORS_SUCCESS,
    SET_DECISION_TYPE
} from './wizardTypes'

import colleges from '../../data/colleges.json'

const initialState = {
    type: null,
    question: null,
    topDecision: null,
    options: [],
    factors: []
}


function updateScores(options, factors){
    var updatedOptions = [];
    const onlySelectedFactors = factors.filter(factor => factor.selected)
    const sumOfWeights = onlySelectedFactors.map((row) => { return row.importance;}).reduce((a, b) => {return parseInt(a) + parseInt(b); }, 0);
    
    for (let i = 0; i < options.length; i++){
        let weighted_grade = 0; 
        for (let j = 0; j < onlySelectedFactors.length; j++){
            weighted_grade += parseInt(onlySelectedFactors[j].importance) * parseInt(onlySelectedFactors[j].grades[i])
        }
        updatedOptions.push({
            choice: options[i].choice,
            score: (sumOfWeights === 0) ? 0 : (weighted_grade / sumOfWeights).toFixed(1)
        })
    }
    return updatedOptions;
}

function topDecision(options) {
    const maxScore = (Math.max(...options.map((row) => { return row.score;}))).toFixed(1);
    let finalResult;
    for (let i = 0; i < options.length; i++){
        if (parseInt(options[i].score) === parseInt(maxScore)){
            finalResult = options[i].choice;
        }
    }
    return finalResult;
}


function getSuggestedGrades(type, factor, currentGrades, options) {
    
    let suggestedGrades = [...currentGrades]

    if (type === "education"){
        if (factor === "Affordability") {
            for (var k = 0; k < options.length; k++){
                for (var i = 0; i < colleges.length; i++){
                    if (colleges[i].Name === options[k].choice){
                        suggestedGrades[k] = colleges[i].affordability
                    }
                }
            }
        } else if (factor === "School Brand") {
            for (var a = 0; a < options.length; a++){
                for (var b = 0; b < colleges.length; b++){
                    if (colleges[b].Name === options[a].choice){
                        suggestedGrades[a] = colleges[b].school_brand
                    }
                }
            }
        } else if (factor === "Alumni Salary") {
            for (var c = 0; c < options.length; c++){
                for (var d = 0; d < colleges.length; d++){
                    if (colleges[d].Name === options[c].choice){
                        console.log(colleges[d].alumni_salary)
                        suggestedGrades[c] = colleges[d].alumni_salary
                    }
                }
            }
        } else if (factor === "Financial Aid") {
            for (var e = 0; e < options.length; e++){
                for (var f = 0; f < colleges.length; f++){
                    if (colleges[f].Name === options[e].choice){
                        suggestedGrades[e] = colleges[f].financial_aid
                    }
                }
            }
        }
    }

    return suggestedGrades
}


function addOptionUpdateSuggestedGrades(type, factor, currentGrades, newOption) {
    
    let suggestedGrades = [...currentGrades, 0];

    if (type === "education"){
        if (factor === "Affordability") {
            for (var i = 0; i < colleges.length; i++){
                if (colleges[i].Name === newOption){
                    suggestedGrades[currentGrades.length] = colleges[i].affordability
                }
            }
        } else if (factor === "School Brand") {
            for (var j = 0; j < colleges.length; j++){
                if (colleges[j].Name === newOption){
                    suggestedGrades[currentGrades.length] = colleges[j].school_brand
                }
            }
        } else if (factor === "Alumni Salary") {
            for (var k = 0; j < colleges.length; k++){
                if (colleges[k].Name === newOption){
                    suggestedGrades[currentGrades.length] = colleges[k].alumni_salary
                }
            }
        } else if (factor === "Financial Aid") {
            for (var l = 0; l < colleges.length; l++){
                if (colleges[l].Name === newOption){
                    suggestedGrades[currentGrades.length] = colleges[l].financial_aid
                }
            }
        }
    }

    return suggestedGrades
}



const wizardReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_QUESTION: {
            return {
                ...state,
                question: action.payload
            }
        }
        case ADD_OPTION: {
            const newFactors = [...state.factors].map((row) => {
                const newGrades = addOptionUpdateSuggestedGrades(state.type, row.factor, row.grades, action.payload)
                return {
                    factor: row.factor,
                    selected: row.selected,
                    importance: row.importance,
                    grades: newGrades,
                    suggestedGrades: getSuggestedGrades(state.type, row.factor, Array(state.options.length + 1).fill(0), [...state.options, {choice: action.payload, score: 0}])
                }
            })

            return {
                ...state,
                options: [...state.options, {choice: action.payload, score: 0}],
                factors: newFactors
            }
        }
        case REMOVE_OPTION: {
            const updatedOptions = [...state.options];
            updatedOptions.splice(action.payload, 1);

            const newFactors = [...state.factors].map((row) => {
                const newGrades = [...row.grades];
                newGrades.splice(action.payload, 1)
                return {
                    factor: row.factor,
                    selected: row.selected,
                    importance: row.importance,
                    grades: newGrades,
                    suggestedGrades: row.suggestedGrades
                }
            })

            return {
                ...state,
                options: updatedOptions,
                factors: newFactors
            }
        }
        case ADD_FACTOR: {
            const grades = Array(state.options.length).fill(0);
            const newFactors = [...state.factors, {
                factor: action.payload, 
                selected: true, 
                importance: 0, 
                grades: grades,
                suggestedGrades: grades
            }];

            return {
                ...state,
                factors: newFactors
            }
        }
        case SELECT_FACTOR: {
            const newArr = [...state.factors];
            const newFactors = newArr.map((row) => {
                if (action.payload.toLowerCase() === row.factor.toLowerCase()){
                    return {
                        factor: row.factor,
                        selected: !row.selected,
                        importance: row.importance,
                        grades: row.grades,
                        suggestedGrades: row.suggestedGrades
                    }
                }else {
                    return {
                        factor: row.factor,
                        selected: row.selected,
                        importance: row.importance,
                        grades: row.grades,
                        suggestedGrades: row.suggestedGrades
                    }
                }
            })
            return {
                ...state,
                factors: newFactors
            };
        }
        case GET_S_FACTORS_SUCCESS: {
            
            // the suggested factors are already included
            const currentFactors = [...state.factors];
            if (currentFactors.length > 0){
                return {
                    ...state
                }
            }
            
            // the first visit to the factors page
            let updatedFactors = [];
            const suggestedFactors = action.payload
            const grades = Array(state.options.length).fill(0);
        
            for (let j = 0; j < suggestedFactors.length; j++){

                const suggestedGrades = getSuggestedGrades(state.type, suggestedFactors[j], grades, state.options);
    
                updatedFactors.push({
                    factor: suggestedFactors[j], 
                    selected: false, 
                    importance: 0,
                    grades: suggestedGrades,
                    suggestedGrades: suggestedGrades
                });
            }
            return {
                ...state,
                factors: updatedFactors
            }
        }
        case REMOVE_FACTOR: {
            const { index } = action.payload
            const newArr = [...state.factors];
            newArr.splice(index, 1);
    
            const newArr2 = [...state.options];
            const newArr3 = newArr2.map((row) => {
                const newGrades = row.grades.slice();
                newGrades.splice(index, 1);
                return {
                    choice: row.choice,
                    selected: row.selected,
                    grades: newGrades,
                    score: row.score
                };
            });
            
            const newFactors = newArr;
            const newOptions = updateScores(newArr3, newFactors);
    
            return {
                ...state,
                topDecision: topDecision(newOptions),
                options: newOptions,
                factors: newFactors
            }
        }
        case ADD_IMPORTANCE: {
            const { factor, importance } = action.payload;
            const newFactors = [...state.factors];
            
            for (let j = 0; j < newFactors.length; j++){
                if (newFactors[j].factor.toLowerCase() === factor.toLowerCase()){
                    newFactors[j] = {
                        factor: newFactors[j].factor,
                        selected: newFactors[j].selected,
                        importance: (importance === "") ? 0 : parseInt(importance),
                        grades: newFactors[j].grades,
                        suggestedGrades: newFactors[j].suggestedGrades
                    }
                }
            }

            const newOptions = updateScores(state.options, newFactors);

            return {
                ...state,
                topDecision: topDecision(newOptions),
                options: newOptions,
                factors: newFactors
            }
        }
        case ADD_GRADE: {
            const { optionIndex, factorIndex, grade } = action.payload;
            
            const newFactors = [...state.factors];
            const newGrades = newFactors[factorIndex].grades.slice(); 
            newGrades[optionIndex] = (grade === "") ? 0 : parseInt(grade);

            newFactors[factorIndex] = {
                factor: newFactors[factorIndex].factor,
                selected: newFactors[factorIndex].selected,
                importance: newFactors[factorIndex].importance,
                grades: newGrades,
                suggestedGrades: newFactors[factorIndex].suggestedGrades,
            }

            const newOptions = updateScores(state.options, newFactors)

            return {
                ...state,
                topDecision: topDecision(newOptions),
                options: newOptions,
                factors: newFactors
            }
        }
        case SET_WIZARD: {
            return {
                ...state,
                type: action.payload.type,
                question: action.payload.question,
                topDecision: action.payload.topDecision,
                options: action.payload.options,
                factors: action.payload.factors
            }
        }
        case RESET_WIZARD: {
            return {
                ...state,
                type: null,
                question: null,
                topDecision: null,
                options: [],
                factors: []
            }
        }
        case ADD_COLORS: {
            return {
                ...state,
                colors: action.payload
            }
        }
        case SET_DECISION_TYPE: {
            return {
                ...state,
                type: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export default wizardReducer