import React from 'react';
import Item from './Item';
import List from '@material-ui/core/List';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
});

function ItemList(props){

    const classes = useStyles();

    const items = props.items.map((item, index) => {
        return <Item content={item} key={index} id={index} onDelete={props.onDelete} />
    })

    return (
        <div>
            <Container maxWidth="sm">
            <Card className={classes.root} variant="outlined"> 
                <CardContent>
                    <List>
                        {items}
                    </List>
                </CardContent>
            </Card>
            </Container>
        </div>
    )
}

export default ItemList