import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      backgroundColor: colors => colors.primaryColor,
      color: colors => colors.primaryTextColor,
      '&:hover': {
        backgroundColor: colors => colors.secondaryColor,
        color: colors => colors.primaryTextColor
      }
    },
});

function MyButton(props) {

    const { colors } = props;
    const classes = useStyles(colors);

    return (
        <Button className={classes.root}
            variant={props.variant}
            component={Link}
            to={props.to}
        >
            {props.name}
        </Button>
    );

}

export default MyButton;