import React from 'react';
import './App.scss';
import Matrix from './components/Matrix';

import {Provider} from 'react-redux'
import store from './redux/store'
import ReactGA from 'react-ga';


const trackingId = 'UA-169393308-1'
ReactGA.initialize(trackingId);


function App() {

  return (
    <Provider store={store}>
        <div className="App">      
              <Matrix/>
        </div>
    </Provider>
  );
}

export default App;
