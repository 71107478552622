export const USER_JOIN_REQUEST = 'USER_JOIN_REQUEST'
export const USER_JOIN_SUCCESS = 'USER_JOIN_SUCCESS'
export const USER_JOIN_FAILURE = 'USER_JOIN_FAILURE'
export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAILURE = 'USER_UPDATE_PROFILE_FAILURE'
export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAILURE = 'USER_SIGNIN_FAILURE'
export const USER_SIGNEDOUT_SUCCESS = 'USER_SIGNEDOUT_SUCCESS'
export const RESET_USER_INFO = 'RESET_USER_INFO'