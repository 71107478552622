import React from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NavBar from './NavBar';
import { getColors } from './utilities/Colors';

const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
          margin: theme.spacing(1),
          flexGrow: 1
      },
      title: {
          flexGrow: 1,
      },
    },
  }));

const Profile = (props) => {

  const { match } = props
  const type = match.params.type;
  const colors = getColors(type);
  const classes = useStyles();

  return (
    <div>
        <NavBar type={"decision matrix"} colors={colors}/>
      <div className={classes.root}>
      <div align="center">
        <Container maxWidth="xs">
            <Typography variant="h4">
              Your Profile  
            </Typography>
            <div className={classes.root}>
            <div align="left">
                <Typography variant="h6">
                Name: {props.user.name}
                </Typography>
                <Typography variant="h6">
                Email: {props.user.email}
                </Typography>
            </div>
            </div>
        </Container>
        </div>
      </div>
      </div>
  );
};

const mapStateToProps = state => {
  return {
      user: state.user.user
  }
}


export default connect(mapStateToProps, null)(Profile);
