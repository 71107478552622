import React from "react";
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import MyButton from './utilities/MyButton';

function Joined(props) {

  const {colors} = props;

  return (
    <div>
        <Typography variant="h4">
              Thank you for joining {props.user.name}!
        </Typography>
        <br/>
        <MyButton 
            variant="contained"
            colors={colors}
            name="Get started!"
            to="/"
        >
        </MyButton>
    </div>
  );
};

const mapStateToProps = state => {
  return {
      user: state.user.user,
  }
}

export default connect(mapStateToProps, null)(Joined)
