import React from 'react';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    slider: {
        color: colors => colors.primaryColor,
    }
});

function MySlider(props) {

    const { colors } = props;
    const classes = useStyles(colors);

    return (
        <Slider
            className={classes.slider}
            valueLabelDisplay="on"
            defaultValue={props.defaultValue}
            aria-labelledby="discrete-slider"
            step={1}
            marks
            min={0}
            max={10}
            onChange={(e, v) => props.addGrade(props.index, props.index2, v)}
        />
    );

}

export default MySlider;