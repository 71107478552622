import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import LaunchIcon from '@material-ui/icons/Launch';

import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { connect } from 'react-redux';

import { useHistory } from "react-router-dom";
import { saveDecision, setWizard, setFirestore } from '../redux';


export function DecisionsMenu(props) {

    const { deleteDecision, saveDecision, setWizard, setFirestore, decision } = props

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let history = useHistory();   
    
  const copyDecision = decision => {
      const updatedDecision = {
          firestore: decision.firestore,
          user: decision.user,
          wizard: {
              factors: decision.wizard.factors,
              options: decision.wizard.options,
              type: decision.wizard.type,
              topDecision: decision.wizard.topDecision
          }
      }
      saveDecision(updatedDecision)
  }

  const openDecision = decision => {
      setWizard(decision.wizard);
      setFirestore(decision.firestore);
      history.push(`/${decision.wizard.type}/results`)
  }

  const shareDecision = decision => {
      const id = decision.firestore.firestoreId
      history.push(`/${decision.wizard.type}/results/${id}`)
  }  

  return (
    <div>
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleClick}
        >
          <MoreHorizIcon/>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
            <MenuItem onClick={() => shareDecision(decision)}>
                <ListItemIcon>
                    <ShareIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Share" />
            </MenuItem>
            <MenuItem onClick={() => openDecision(decision)}>
                <ListItemIcon>
                    <LaunchIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Open" />
            </MenuItem>
            <MenuItem onClick={() => copyDecision(decision)}>
                <ListItemIcon>
                    <FileCopyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Copy"/>
            </MenuItem>
            <MenuItem onClick={() => deleteDecision(decision)}>
                <ListItemIcon>
                    <DeleteOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Delete"/>
            </MenuItem>
        </Menu>
      </div>
  );
}

const mapDispatchToProps = dispatch => {
    return {
        setWizard: wizard => dispatch(setWizard(wizard)),
        setFirestore: firestore => dispatch(setFirestore(firestore)),
        saveDecision: decision => dispatch(saveDecision(decision)),
    }
}

export default connect(null, mapDispatchToProps)(DecisionsMenu)