import { 
    USER_JOIN_REQUEST,
    USER_JOIN_SUCCESS,
    USER_JOIN_FAILURE,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAILURE,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_SUCCESS,
    USER_SIGNIN_FAILURE,
    USER_SIGNEDOUT_SUCCESS,
    RESET_USER_INFO
} from './userTypes'

const initialState = {
    user: {
        uid:'',
        name:'',
        email:''
    },
    loading: false,
    loaded: false,
    error: ''
}

const userReducer = (state = initialState, action) => {
    switch(action.type) {
        case USER_JOIN_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false
            }
        case USER_JOIN_SUCCESS:
            return {
                ...state,
                user: action.payload
            }
        case USER_JOIN_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case USER_UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case USER_UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true
            }
        case USER_UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                error: action.payload
            }  
        case USER_SIGNIN_REQUEST:
            return {
                ...state,
                loading: true,
                loaded: false
            }
        case USER_SIGNIN_SUCCESS:
            return {
                ...state, 
                user: action.payload,
                loading: false,
                loaded: true
            }
        case USER_SIGNIN_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case USER_SIGNEDOUT_SUCCESS:
            return {
                ...state,
                user: {
                    uid: '',
                    name: '',
                    email: ''
                }
            }

        case RESET_USER_INFO:
            return {
                ...state,
                user: {
                    uid: '',
                    name: '',
                    email: ''
                },
                loading: false,
                loaded: false
            }
        default: {
            return state
        }
    }
}

export default userReducer
