import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CreateIcon from '@material-ui/icons/Create';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { useHistory } from "react-router-dom";

import { resetWizard, resetFirestore, userSignOut, resetUserInfo } from '../redux';


export function SimpleMenu(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const newDecision = () => {
    props.resetWizard();
    props.resetFirestore();
  }

  let history = useHistory();   

  const signOut = () => {
    props.userSignOut();
    props.resetUserInfo();
    history.push('/signed_out')
  }

  let dropDowns;
  if (props.user.name !== "") {
    dropDowns = 
      <div>
        <MenuItem component={Link} to={`/profile`}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </MenuItem>
        <MenuItem component={Link} to="/" onClick={() =>  newDecision()}>
          <ListItemIcon>
            <CreateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="New decision"/>
        </MenuItem>
        <MenuItem component={Link} to={`/decisions`}>
          <ListItemIcon>
            <SaveOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Saved decisions"/>
        </MenuItem>
        <MenuItem component={Link} to={`/signed_out`} onClick={() => signOut()}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sign out"/>
        </MenuItem>
      </div>
  } else {
    dropDowns = 
      <div>
        <MenuItem component={Link} to={`/sign_in`}>
          <ListItemIcon>
            <LockOpenIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sign in" />
        </MenuItem>
        <MenuItem component={Link} to={`/join`}>
          <ListItemIcon>
            <PersonAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Join" />
        </MenuItem>
        <MenuItem component={Link} to="/" onClick={() =>  newDecision()}>
          <ListItemIcon>
            <CreateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="New decision"/>
        </MenuItem>
      </div>;
  }

  return (
    <div>
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleClick}
        >
          <AccountCircleIcon/>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {dropDowns}
        </Menu>
      </div>
  );
}

const mapStateToProps = state => {
  return {
      user: state.user.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
      resetWizard: () => dispatch(resetWizard()),
      resetFirestore: () => dispatch(resetFirestore()),
      userSignOut: () => dispatch(userSignOut()),
      resetUserInfo: () => dispatch(resetUserInfo()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleMenu)