import React from "react";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import LoadingSpinner from './utilities/LoadingSpinner';
import SignInForm from './SignInForm';
import { connect } from 'react-redux';
import SignedIn from "./SignedIn";
import NavBar from './NavBar';
import { getColors } from './utilities/Colors';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
        margin: theme.spacing(1),
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
    },
  },
}));

function SignIn(props) {

  const colors = getColors(null);
  const classes = useStyles();

  if (!props.loading && !props.loaded) {
    return (
      <div>
        <NavBar type={"decision matrix"} colors={colors}/>
      <div className={classes.root}>
        <div align="center">
        <Container maxWidth="xs">
            <SignInForm colors={colors}/>
          </Container>
        </div>
      </div>
      </div>
    );
  } else if (props.loading && !props.loaded) {
    return (
      <div>
        <NavBar type={"decision matrix"} colors={colors}/>
      <div className={classes.root}>
        <div align="center">
        <Container maxWidth="xs">
            <LoadingSpinner colors={colors}/>
          </Container>
        </div>
      </div>
      </div>
    );
  } else {
    return (
      <div>
        <NavBar type={"decision matrix"} colors={colors}/>
      <div className={classes.root}>
        <div align="center">
        <Container maxWidth="xs">
            <SignedIn colors={colors}/>
          </Container>
        </div>
      </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
      loading: state.user.loading,
      loaded: state.user.loaded
  }
}

export default connect(mapStateToProps, null)(SignIn)
