import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { userSignIn } from '../redux'


const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
        margin: theme.spacing(1),
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
    },
  },
  textField: {
    "& label.Mui-focused": {
        color: colors => colors.primaryColor,
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: colors => colors.primaryColor,
    }
  },
  button: {
    backgroundColor: colors => colors.primaryColor,
    color: colors => colors.primaryTextColor,
    '&:hover': {
      backgroundColor: colors => colors.secondaryColor,
      color: colors => colors.primaryTextColor
    }
  },
}));

const SignInForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, ] = useState("");

  const { colors } = props;
  const classes = useStyles(colors);

  const handleForm = e => {
    e.preventDefault();
    props.userSignIn(email, password)
  };

  return (
    <div className={classes.root}>
      <div align="center">
      <Container maxWidth="xs">
            <Typography variant="h4">
              Login  
            </Typography>
            <div className={classes.root}>
            <TextField
              className={classes.textField}
              value={email}
              onChange={e => setEmail(e.target.value)}
              fullWidth
              name="email"
              type="email"
              placeholder="email"
            />
            <br/>
            <TextField
              className={classes.textField}
              onChange={e => setPassword(e.target.value)}
              name="password"
              fullWidth
              value={password}
              type="password"
              placeholder="password"
            />
          <br/>
          <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={e => handleForm(e)}
          >
              Sign in
          </Button>
          </div>
          <span>{error}</span>
        </Container>
        </div>
      </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
      userSignIn: (email, password) => dispatch(userSignIn(email, password)),
  }
}

export default connect(null, mapDispatchToProps)(SignInForm)