import { 
    USER_JOIN_REQUEST,
    USER_JOIN_SUCCESS,
    USER_JOIN_FAILURE,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAILURE,
    USER_SIGNIN_REQUEST,
    USER_SIGNIN_SUCCESS,
    USER_SIGNIN_FAILURE,
    USER_SIGNEDOUT_SUCCESS,
    RESET_USER_INFO
} from './userTypes'

import firebase from '../../firebase';

export const userJoinRequest = () => {
    return {
        type: USER_JOIN_REQUEST
    }
}

export const userJoinSuccess = user => {
    return {
        type: USER_JOIN_SUCCESS,
        payload: user
    }
}

export const userJoinFailure = error => {
    return {
        type: USER_JOIN_FAILURE,
        payload: error
    }
}

export const userUpdateProfileRequest = () => {
    return {
        type: USER_UPDATE_PROFILE_REQUEST
    }
}

export const userUpdateProfileSuccess = name => {
    return {
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: name
    }
}

export const userUpdateProfileFailure = error => {
    return {
        type: USER_UPDATE_PROFILE_FAILURE,
        payload: error
    }
}

export const userSignInRequest = () => {
    return {
        type: USER_SIGNIN_REQUEST
    }
}

export const userSignInSuccess = (user) => {
    return {
        type: USER_SIGNIN_SUCCESS,
        payload: user
    }
}

export const userSignInFailure = (error) => {
    return {
        type: USER_SIGNIN_FAILURE,
        error: error
    }
}

export const userSignedOutSuccess = () => {
    return {
        type: USER_SIGNEDOUT_SUCCESS,
    }
}

export const resetUserInfo = () => {
    return {
        type: RESET_USER_INFO,
    }
}

export const userSignIn = (email, password) => {
    return (dispatch) => {
        dispatch(userSignInRequest)
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(res => {
                if (res.user) {
                    dispatch(userSignInSuccess(
                        {
                            uid: res.user.uid,
                            name: res.user.displayName,
                            email: email
                        }
                    ));
                }
            })
            .catch(error => {
                    dispatch(userSignInFailure(error))
            })
    }

}

export const userSignOut = () => {
    return (dispatch) => {
        console.log("signing out!")
        firebase
            .auth().signOut()
            .then(() => {
                dispatch(userSignedOutSuccess)
                console.log("success")
            })
            .catch(error => {
                console.log("error")
            });
    }
}

export const userJoin = (name, email, password) => {
    return (dispatch) => {
        dispatch(userJoinRequest)
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(res => {
                if(res.user){
                    dispatch(userJoinSuccess(
                        {
                            uid: res.user.uid, 
                            name: name,
                            email: email
                        }));
                    dispatch(userUpdateProfileRequest)
                    res.user
                        .updateProfile({displayName: name})
                        .then(function() { 
                            console.log("user display name updated");
                            dispatch(userUpdateProfileSuccess(res.user.displayName))
                        })
                        .catch(e => {
                            dispatch(userUpdateProfileFailure(e.message));
                        });
                }
            })
            .catch(e => {
                dispatch(userJoinFailure(e.message))
            })
    }
}