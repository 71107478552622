import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { getColors } from './utilities/Colors';
import DecisionsMenu from './DecisionsMenu';
import NavBar from './NavBar';

import education from "../icons/graduation-hat.svg"; 
import relocation from "../icons/placeholder.svg"; 
import career from "../icons/presentation.svg"; 
import relationship from "../icons/love-letter_1.svg"; 
import custom from "../icons/magnifying-glass.svg";
import matrix from "../icons/matrix.svg";
import home from "../icons/home.svg";

import { connect } from 'react-redux';
import { getDecisions, deleteDecision } from '../redux';



const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
          margin: theme.spacing(1),
          flexGrow: 1
      },
      title: {
          flexGrow: 1,
      },
    },
}));

function getIconHTML(type) {
    let image;

    if (type === "relocation"){
      image = <img width="30" height="30" src={relocation} alt=''/>
    } else if (type === "education"){
      image = <img width="30" height="30" src={education} alt=''/>
    } else if (type === "career"){
      image = <img width="30" height="30" src={career} alt=''/>
    } else if (type === "relationship"){
      image = <img width="30" height="30" src={relationship} alt=''/>
    } else if (type === "custom") {
      image = <img width="30" height="30" src={custom} alt=''/>
    } else if (type === "home") {
        image = <img width="30" height="30" src={home} alt=''/>
    } else {
      image = <img width="30" height="30" src={matrix} alt=''/>
    }
    return image
}


function Decisions (props) {

    const { user, getDecisions, decisions, reload, deleteDecision } = props

    const classes = useStyles();
    const colors = getColors(null);

    React.useEffect(() => {
        getDecisions(user)
    }, [getDecisions, user, reload])

    return (
        <div>
            <NavBar type={"decision matrix"} colors={colors}/>
            <div className={classes.root}>
                <div align="center">
                    <Container maxWidth="sm">
                        <Typography variant="h4">
                            Your saved decisions
                        </Typography>
                        <br/>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Type</b></TableCell>
                                        <TableCell align="left"><b>Decision</b></TableCell>
                                        <TableCell align="left"><b>Actions</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {decisions !== [] && decisions.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {getIconHTML(row.wizard.type)}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.wizard.topDecision}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DecisionsMenu
                                                    decision={row}
                                                    deleteDecision={deleteDecision}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        decisions: state.firestore.decisions,
        reload: state.firestore.reload,
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDecisions: user => dispatch(getDecisions(user)),
        deleteDecision: index => dispatch(deleteDecision(index))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Decisions)