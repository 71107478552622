import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { getColors } from '../utilities/Colors';

import { connect } from 'react-redux';
import { saveDecision, updateDecision, setDecisionType } from '../../redux';
import NavBar from '../NavBar';

import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        '& > *': {
            margin: 1,
            flexGrow: 1
        },
        title: {
            flexGrow: 1,
        },
    },
    button: {
        backgroundColor: colors => colors.primaryColor,
        color: colors => colors.primaryTextColor,
        '&:hover': {
          backgroundColor: colors => colors.secondaryColor,
          color: colors => colors.primaryTextColor
        }
    },
});

function Results(props){

    const { match } = props
    const type = match.params.type;
    const colors = getColors(type);
    const classes = useStyles(colors);
    const { decisionToSave, saveDecision, updateDecision, setDecisionType } = props
    const { options, topDecision } = decisionToSave.wizard
    const { firestoreId } = decisionToSave.firestore

    React.useEffect(() => {
        setDecisionType(type)
    }, [setDecisionType, type])

    let saveButton;

    if (firestoreId !== "") {
        saveButton = 
            <Button 
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => updateDecision(firestoreId, decisionToSave)}
                >
                Update
            </Button>
    }else{
        saveButton = 
            <Button 
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => saveDecision(decisionToSave)}
                >
                Save
            </Button>
    }

    return (
        <div>
        <NavBar type={type} colors={colors}/>
        <div className={classes.root}>
            <div align="center">
                <Container maxWidth="sm">
                    <Typography variant="h4">
                        {topDecision}!
                    </Typography>
                    <br/>
                    <Typography align="left" varient="body1" display='block'>
                        After running the calculations, you should go with 
                        the <b>{topDecision}</b> option. Feel free to go back and adjust 
                        the factors, importance rankings, and scores to see if you get another result.
                    </Typography>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell><b>Choice</b></TableCell>
                                <TableCell align="right"><b>Score</b></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {options.map((row, index) => (
                                <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.choice}
                                </TableCell>
                                <TableCell align="right">
                                    {row.score}
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
                <br/>
                <div className={classes.root}>
                    <Button 
                        variant="contained"
                        component={Link}
                        to={`/${type}/scoring`}
                        >
                        Back
                    </Button>
                    {saveButton}
                </div>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        decisionToSave: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDecisionType: (type) => dispatch(setDecisionType(type)),
        saveDecision: (decisionToSave) => dispatch(saveDecision(decisionToSave)),
        updateDecision: (firestoreId, decisionToSave) => dispatch(updateDecision(firestoreId, decisionToSave)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results)
