import React from "react";
import MuiAppbar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from '@material-ui/core/styles';
import SimpleMenu from './SimpleMenu';
import { resetWizard, resetFirestore } from '../redux';
import education from "../icons/graduation-hat.svg"; 
import relocation from "../icons/placeholder.svg"; 
import career from "../icons/presentation.svg"; 
import relationship from "../icons/love-letter_1.svg"; 
import custom from "../icons/magnifying-glass.svg";
import matrix from "../icons/matrix.svg";
import home from "../icons/home.svg";

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    '& > *': {
        margin: 1,
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
    },
    menuButton: {
      marginRight: 2,
    },
  },
  navbar: {
    backgroundColor: colors => colors.primaryColor,
  },
  button: {
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  }
});

function NavBar(props) {
    
    const {colors, type} = props;
    const classes = useStyles(colors);

    const newDecision = () => {
      props.resetWizard();
      props.resetFirestore();
    }

    let welcomeMessage = ""
    if (props.user.name !== "") {
        welcomeMessage = props.user.name
    } else {
        welcomeMessage = ""
    }

    let image;

    if (type === "relocation"){
      image = <img width="30" height="30" src={relocation} alt=''/>
    } else if (type === "education"){
      image = <img width="30" height="30" src={education} alt=''/>
    } else if (type === "career"){
      image = <img width="30" height="30" src={career} alt=''/>
    } else if (type === "relationship"){
      image = <img width="30" height="30" src={relationship} alt=''/>
    } else if (type === "custom") {
      image = <img width="30" height="30" src={custom} alt=''/>
    } else if (type === "home") {
      image = <img width="30" height="30" src={home} alt=''/>
    } else {
      image = <img width="30" height="30" src={matrix} alt=''/>
    }


    return (
      <div className={classes.root}>
        <Grid container>
          <MuiAppbar position="static" className={classes.navbar}>
            <Toolbar>
            <Grid item>
                <IconButton 
                  className={classes.button}
                  edge="start" 
                  component={Link}
                  to="/"
                  onClick={() =>  newDecision()}>
                    {image}
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  color="inherit"
                  style={{ marginLeft: "8px" }}
                >
                  {type.toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs>
              </Grid>
              <Grid item>
                {welcomeMessage}
              </Grid>
              <Grid item>
                <SimpleMenu type={type}/>
              </Grid>
            </Toolbar>
          </MuiAppbar>
        </Grid>
        <br/>
      </div>
    )
};

const mapStateToProps = state => {
  return {
      user: state.user.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
      resetWizard: () => dispatch(resetWizard()),
      resetFirestore: () => dispatch(resetFirestore()),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(NavBar);