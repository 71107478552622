export const ADD_OPTION = 'ADD_OPTION'
export const SELECT_FACTOR = 'SELECT_FACTOR'
export const REMOVE_OPTION = 'REMOVE_OPTION'
export const ADD_FACTOR = 'ADD_FACTOR'
export const REMOVE_FACTOR = 'REMOVE_FACTOR'
export const ADD_IMPORTANCE = 'ADD_IMPORTANCE'
export const ADD_GRADE = 'ADD_GRADE'
export const SET_WIZARD = 'SET_WIZARD'
export const ADD_QUESTION = 'ADD_QUESTION'
export const RESET_WIZARD = 'RESET_WIZARD'
export const ADD_COLORS = 'ADD_COLORS'
export const GET_S_FACTORS_SUCCESS = 'GET_S_FACTORS_SUCCESS'
export const SET_DECISION_TYPE = 'SET_DECISION_TYPE'