import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavBar from '../NavBar';
import { getColors } from '../utilities/Colors';

import { connect } from 'react-redux';
import { getDecision, setWizard } from '../../redux';

const useStyles = makeStyles({
    root: {
        '& > *': {
            margin: 1,
            flexGrow: 1
        },
        title: {
            flexGrow: 1,
        },
    },
});

function Results2(props){

    const classes = useStyles();
    const { decision, getDecision, setWizard, match } = props

    const type = match.params.type;
    const colors = getColors(type);

    React.useEffect((decision) => {
        getDecision(match.params.id)
    }, [getDecision, setWizard, match])

    let tableBody;

    if (decision !== undefined && decision.options !== undefined){
        tableBody = 
            <TableBody>
            {decision.options.map((row, index) => (
                <TableRow key={index}>
                <TableCell component="th" scope="row">
                    {row.choice}
                </TableCell>
                <TableCell align="right">
                    {row.score}
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
    }else{
        tableBody = <TableBody>Loading!</TableBody>
    }

    return (
        <div>
        <NavBar type={type} colors={colors}/>
        <div className={classes.root}>
            <div align="center">
                <Container maxWidth="sm">
                    <Typography variant="h4">
                        {decision.topDecision}!
                    </Typography>
                    <br/>
                    <Typography align="left" varient="body1" display='block'>
                        After running the calculations, you should go with 
                        the <b>{decision.topDecision}</b> option. Feel free to go back and adjust 
                        the factors, importance rankings, and scores to see if you get another result.
                    </Typography>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>Choice</b></TableCell>
                                    <TableCell align="right"><b>Score</b></TableCell>
                                </TableRow>
                            </TableHead>
                            {tableBody}
                        </Table>
                    </TableContainer>
                </Container>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        decision: state.firestore.decision
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setWizard: wizard => dispatch(setWizard(wizard)),
        getDecision: (id) => dispatch(getDecision(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results2)
