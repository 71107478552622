export function getColors(type) {
    var colors = {};
    if (type === "relocation") {
         colors = {
             primaryColor: '#cd2a00',
             secondaryColor: '#a42100',
             primaryTextColor: 'white',
             secondaryTextColor: 'black'
         };
    } else if (type === "education"){
         colors = {
             primaryColor: '#fdbf00',
             secondaryColor: '#ca9800',
             primaryTextColor: 'white',
             secondaryTextColor: 'black'
         };
    } else if (type === "career"){
         colors = {
             primaryColor: '#00aa8e',
             secondaryColor: '#008871',
             primaryTextColor: 'white',
             secondaryTextColor: 'black'
         };
    } else if (type === "relationship"){
         colors = {
             primaryColor: '#6c65d4',
             secondaryColor: '#5650a9',
             primaryTextColor: 'white',
             secondaryTextColor: 'black'
         };
    } else if (type === "custom") {
        colors = {
            primaryColor: '#0b389c',
            secondaryColor: '#082c7c',
            primaryTextColor: 'white',
            secondaryTextColor: 'black'
        };
    } else if (type === "home") {
        colors = {
            primaryColor: '#ff4e19',
            secondaryColor: '#cc3e14',
            primaryTextColor: 'white',
            secondaryTextColor: 'black'
        };
    } else {
        colors = {
            primaryColor: '#738694',
            secondaryColor: '#5C7080',
            primaryTextColor: 'white',
            secondaryTextColor: 'black'
        };
    }
    return colors;
}