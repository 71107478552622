import React from 'react'

import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';

function Item(props){
    return (
        <div className='list-item'>
            <ListItem>
                <ListItemText
                    primary={props.content}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" 
                        onClick={() =>  {props.onDelete(props.id)}}>
                    <DeleteOutline />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    )
}

export default Item
