import React from 'react'

import Typography from '@material-ui/core/Typography';

function OptionsText(props) {

    let title; 
    let description;

    if (props.type === "relocation") {
        title="Locations";
        description="Pick the cities you are deciding between."
    } else if (props.type === "education") {
        title="Schools"
        description="Pick the schools you are deciding between."
    } else if (props.type === "career") {
        title="Jobs"
        description="Pick the jobs you are deciding between."
    } else if (props.type === "relationship") {
        title="People"
        description="Pick the people you are deciding between."
    } else if (props.type === "home") {
        title="Homes"
        description="Pick the homes you are deciding between."
    } else {
        title="Options"
        description="Add the options you are deciding between."
    }

    return (
        <div>
            <Typography variant="h4">
                {title}
            </Typography>
            <br/>
            <Typography align="center" varient="body1" display='block'>
                {description}
            </Typography>
        </div>
    )
}

export default OptionsText