import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

function Review(props) {
    return (
        <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Card >
                <CardMedia style={{height: 0, paddingTop: '56.25%'}} image={props.image}/>
                <CardContent>
                    <Typography gutterBottom variant="h4" component="h2">
                        {props.name}
                    </Typography>
                    <Typography component="p">
                        {props.review}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Review